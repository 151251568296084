<template>
  <div class="privacy-page">
    <h1>Politique de Confidentialité</h1>
    <p>L'Union Nationale des Assemblées de Dieu de France (UNADF), située au 33 RUE DE COUHINS, 33850 LEOGNAN, France,
      s'engage à protéger les données personnelles de ses utilisateurs conformément aux lois en vigueur en matière de
      protection des données.</p>

    <h2>Contact :</h2>
    <ul>
      <li>Adresse : 33 RUE DE COUHINS, 33850 LEOGNAN, France</li>
      <li>Email : <a href="mailto:pdt.unadf@addfrance.fr">pdt.unadf@addfrance.fr</a></li>
      <li>Hébergeur : Ikoula - 2 Cité Paradis, 75010 Paris, France</li>
    </ul>

    <h2>Collecte et Utilisation des Données</h2>
    <p>Les données collectées sont limitées aux informations strictement nécessaires pour assurer le bon fonctionnement
      interne des Assemblées de Dieu de France. Ces données comprennent :</p>
    <ul>
      <li>Nom</li>
      <li>Prénom</li>
      <li>Adresse email</li>
    </ul>
    <p>Ces informations sont obligatoires pour l'inscription et l'utilisation des services proposés par l'UNADF.</p>

    <h2>Finalité de l'Utilisation des Données</h2>
    <p>Les données personnelles collectées sont utilisées uniquement pour le fonctionnement interne des activités des
      Assemblées de Dieu de France. Elles ne sont ni vendues, ni partagées avec des tiers à des fins commerciales ou
      promotionnelles.</p>

    <h2>Conservation des Données</h2>
    <p>Les données sont conservées uniquement pendant la durée de l'exercice des fonctions de l'utilisateur au sein de
      l'association. Une fois que l'utilisateur cesse ses fonctions, toutes les données personnelles le concernant
      seront supprimées.</p>

    <h2>Sécurité des Données</h2>
    <p>L'Union Nationale des Assemblées de Dieu de France met en œuvre des mesures techniques et organisationnelles pour
      protéger les données personnelles contre tout accès, utilisation ou divulgation non autorisés.</p>

    <h2>Droits des Utilisateurs</h2>
    <p>Conformément à la réglementation en vigueur, les utilisateurs disposent d'un droit d'accès, de rectification, de
      suppression, et d'opposition concernant leurs données personnelles. Toute demande peut être adressée par email à :
      <a href="mailto:pdt.unadf@addfrance.fr">pdt.unadf@addfrance.fr</a>.
    </p>

  </div>
</template>

<script>
export default {
  name: 'PrivacyPage'
}
</script>

<style scoped>
.privacy-page {
  padding: 20px;
}

.privacy-page h1 {
  font-size: 2em;
  margin-bottom: 10px;
}

.privacy-page p {
  font-size: 1.2em;
}
</style>